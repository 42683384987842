<template>
    <div>
      <h2>Galéria - 2023</h2>
      <image-galery subfolder="galery2023"></image-galery>

      <h2>Galéria - 2021</h2>
      <image-galery subfolder="galery2021"></image-galery>
      
      <h2>Galéria - 2020</h2>
      <image-galery subfolder="galery2020"></image-galery>
    </div>
</template>

<script>
  import ImageGaleryComponent from './subcomponents/ImageGaleryComponent.vue'

  export default {
    name: 'galery',
    components: {
      'image-galery': ImageGaleryComponent
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
